/* ALL About */

.background {
    width: 100%;
    margin-top: 6rem;
    background: white;
}

.descText {
    font-size: 1rem;
    padding-top: 2rem;
    line-height: 2rem;
    color: var(--text);
}

.descText__whiteText {
    color: white;
}

.btnContainer {
    padding-top: 2rem;
    display: flex;
}

.btn {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    background-color: transparent;
    color: white;
    font-weight: bold;
    opacity: 0.8;
    text-decoration: none;
}

.btn__accentText {
    color: rgb(var(--accent));
}

.btn:hover {
    opacity: 1;
}

.btn:active {
    color: rgb(var(--accent));
}

.rightArrow {
    border: solid rgb(var(--accent));
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    animation: movement 1s linear infinite;
}

@keyframes movement {
    50% {
        transform: translateX(50%) rotate(-45deg) scale(1.2);
    }
}


/* START section */

.start__background {
    width: 100%;
    overflow: hidden;
    background: rgb(var(--background));

    /*full screen in mobile first section (cut browser search bar on mobile) + js code*/
    height: calc(100vh - 6rem);
    height: calc(var(--vh, 1vh) * 100 - 6rem);
}

.start {
    height: 100%;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start__img {
    animation: resize 20s ease-out forwards;
}

@keyframes resize {
    100% {
        transform: scale(0.6);
    }
}

.start__blackCover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(var(--background), 0.2);
}

.start__titleContainer {
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
}

.start__title1 {
    color: white;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}

.start__title2 {
    color: white;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 3px;
    margin-top: 1rem;
    text-align: center;
}

.start__arrow {
    position: absolute;
    bottom: 8rem;
    left: 50%;
    width: 1.5rem;
    height: 1.5rem;
    border: solid white;
    border-width: 0.2rem 0.2rem 0 0;
    transform: rotate(135deg) translate(40%);
    animation: moveArrow 1s ease-in-out infinite;
}

@keyframes moveArrow {
    50% {
        transform: rotate(135deg) translateY(40%);
    }
}


/* ABOUT section */


.about {
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    justify-content: center;

}

.about__imgContainer {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
}

.about__desc {
    flex: 1;
    padding: 2rem 1rem 0 1rem;

}


/* CONTACT section */

.contact {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%), url("../../assets/project_img.jpg") no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    padding: 8rem 0;
}

/* support for background fixed with mobile iOS*/
@supports (-webkit-overflow-scrolling: touch) {
    .contact {
        background-attachment: scroll;
    }
}

.contact__desc {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
}

.contact__descText {
    color: white;
    font-size: 2.5rem;
    padding-top: 4rem;
    font-weight: bolder;
}

/* WHY section */

.why {
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.why__desc {
    flex: 1;
    padding: 0 1rem;
}

.why__descItems {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    padding-top: 2rem;
}

.why__descItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: rgba(var(--accent), 0.5) 0.1rem solid;
    box-shadow: 0 0 5px rgba(var(--accent), 0.5);
}

.why__descItem:hover {
    box-shadow: 0 0 20px rgba(var(--accent), 0.5);
}


.why__descIcon {
    height: 3rem;
    width: 3rem;
    fill: rgb(var(--accent));
}

.why__descTextBig {
    font-size: 1.2rem;
    font-weight: bold;
    padding-top: 1rem;

}

.why__descText {
    font-size: 1rem;
    padding-top: 1rem;
    line-height: 1.5rem;
    color: var(--text);
}


/* REALIZATIONS section */

.realizations {
    background: rgb(var(--background));
    padding: 8rem 0;
}

.realizations__desc {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem;
}

.realizations__imgContainer {
    padding: 4rem 0 2rem 0;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
}

.realizations__img {
    width: 100%;
    height: 100%;
}

/* OFFER section */


.offer {
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    align-items: center;
    justify-content: center;
}

.offer__desc {
    flex: 1;
    padding: 2rem 1rem 0 1rem;
    order: 1;
}

.offer__imgContainer {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    order: 0;
}


/* media */

@media (min-width: 500px) {

    .contact__descText {
        font-size: 3.5rem;
    }

    .why__descItems {
        grid-template-columns: repeat(2, 1fr);
    }

    .realizations__imgContainer {
        grid-template-columns: repeat(4, 1fr);
    }

}

@media (min-width: 600px) {
    .start__titleContainer {
        top: 8rem;
        align-items: flex-start;

    }

    .start__title1 {
        font-size: 2.8rem;
        text-align: start;
    }

    .start__title2 {
        font-size: 1.5rem;
        text-align: start;

    }

}

@media (min-width: 800px) {

    .about {
        flex-direction: row;
    }

    .about__desc {
        padding: 0 1rem 0 1rem;
    }

    .why {
        flex-direction: row;
    }

    .why__descItems {
        grid-template-columns: repeat(4, 1fr);
    }

    .offer {
        flex-direction: row;
    }

    .offer__desc {
        padding: 0 1rem 0 1rem;
    }
}