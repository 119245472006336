.background {
    background: rgb(var(--background));
}

.container {
    margin: 0px auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
}

.text {
    text-align: center;
    padding: 1rem;
    color: white;
    text-decoration: none;
    opacity: 1;
    font-size: 1rem;
}

.textLeft {
    padding-right: 4px;
}

.textRight {
    padding-left: 4px;
}

.link {
    cursor: pointer;

}

.link:hover {
    opacity: 1;
}

.designedBy {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.logo {
    height: 20px;
    width: 20px;
}


@media (min-width:800px) {
    .container {
        flex-direction: row;
    }
}