.background {
    width: 100%;
    min-height: calc(100vh - 15rem);
    margin-top: 6rem;
    background: var(--backgroundCustom);
}

/* START section */

.start__background {
    background: rgb(var(--background));
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.start {
    height: 6rem;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start__img {
    width: 100%;
    height: auto;
    animation: resize 20s ease-out forwards;
}

.start__blackCover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(var(--background), 0.2);
}

@keyframes resize {
    100% {
        transform: scale(0.6);
    }
}


/* sections */

.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* START of contact links */

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.5rem;
}

.contentData {
    display: flex;
    flex-direction: column;
}

.contentDataTitle {
    font-size: 2rem;
    color: black;
    padding: 1.5rem 1.5rem 0 0;

}

.contentDataText {
    font-size: 1.5rem;
    color: var(--text);
    padding: 1rem 0 0 1.5rem;
}

.contentItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem 0 0 1.5rem;
    text-decoration: none;
}

.content .contentDataText:nth-child(1) {
    animation: shiftFromLeft .8s 0s ease-out both;
}

.content .contentDataText:nth-child(2) {
    animation: shiftFromLeft .8s .25s ease-out both;
}

.content .contentItem:nth-child(3) {
    animation: shiftFromLeft 1s .5s ease-out both;
}

.content .contentItem:nth-child(4) {
    animation: shiftFromLeft .8s .75s ease-out both;
}

.content .contentItem:nth-child(5) {
    animation: shiftFromLeft .8s 1s ease-out both;
}

.content .contentItem:nth-child(6) {
    animation: shiftFromLeft .8s 1.25s ease-out both;
}

.content .contentItem:nth-child(7) {
    animation: shiftFromLeft .8s 1.5s ease-out both;
}

@keyframes shiftFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.contentItem:active {
    background-color: rgba(var(--accent), 0.4);
}

.contentIcon {
    flex: 1 0 auto;
    width: 2rem;
    height: 2rem;
    fill: rgb(var(--accent));
}


.contentIcon:hover {
    background-color: rgba(var(--accent), 0.2);
}

.contentDesc {
    width: 100%;
    padding: 0 0 0 0.5rem;
    color: var(--text);
    font-size: 1.5rem;

    /* text-align: center; */
}

/* END of contact links */

/* START of form */

.formContainerMain {
    padding: 1.5rem;
    width: 100%;
    flex: 1;
    position: relative;
}

.formContainer {
    position: relative;
}

.form {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    color: #8f8f8f;
}

.inputContainer {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 4rem;
    align-self: center;
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px transparent solid;
    color: black;
    font-size: 1.5rem;
    outline: none;
}

/* custom scroll bar in textarea */
textarea::-webkit-scrollbar {
    width: 0.2rem;
}

textarea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

textarea::-webkit-scrollbar-thumb {
    background: rgba(var(--accent), 0.5);
    border-radius: 999px;
}

.inputIsInvalid {
    border: red 1px solid;
}

.label {
    font-size: 1.5rem;
    padding: 1rem;
    color: rgb(110, 110, 110);
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: 0.5s ease;
}

.isValid {
    position: absolute;
    bottom: 3rem;
    left: 0;
    color: red;
    font-size: 0.8rem;
}

.input:focus+.label,
.input:required:valid+.label {
    /* to work in htmml must be required:true*/
    display: flex;
    transform: scale(0.7) translate(-1rem, -4rem);
    color: rgb(var(--accent));
}

.input:focus {
    border: rgb(var(--accent)) solid;
    border-width: 0 .3rem 0 .3rem;
}

/* Change background and color if input is autocompleate ;) */
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 9999s ease-in-out 0s;
}

.input::placeholder {
    color: rgb(190, 190, 190);
}

.btn {
    align-self: flex-end;
    font-size: 1.5rem;
    background-color: transparent;
    border: 2px solid rgb(var(--accent));
    color: black;
    padding: 0.5rem 2rem;
    cursor: pointer;
    outline: none;
}

.btn:hover {
    background-color: rgba(var(--accent), 0.4);
}

.btn:active {
    background-color: rgba(var(--accent), 0.8);
    box-shadow: 0 0 40px rgba(var(--accent), 0.8);
    color: white;
}

.formAnim {
    animation: formAnimation 4s ease-in forwards;
}

@keyframes formAnimation {
    25% {
        transform: scale(0, 0);
        opacity: 0;
    }

    75% {
        transform: scale(0, 0);
        opacity: 1;
    }
}

.envelope {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0, 0);
}

.envelopeAnim {
    animation: envelopeAnimation 4s 0.5s ease-in forwards;
}

@keyframes envelopeAnimation {
    25% {
        transform: scale(0.2, 0.2) translateX(0) rotate(0);
        opacity: 1;
    }

    50% {
        transform: scale(0.2, 0.2) translateX(0) rotate(0);
        opacity: 1;
    }

    75% {
        transform: scale(0.2, 0.2) translateX(1000%) rotate(720deg);
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.svgOkId {
    opacity: 0;
}

.svgOkIdAnim {
    animation: envelopeSvgOkIdAnimation 4s 0.5s ease-in;
}

@keyframes envelopeSvgOkIdAnimation {
    0% {
        opacity: 0;
    }

    25% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }
}



/* END of form */

@media (min-width: 380px) {

    .contentDesc {
        text-align: start;
    }
}

@media (min-width: 800px) {

    .start {
        max-width: 1200px;
        margin: auto;
        height: 12rem;
    }

    .section {
        max-width: 1200px;
        margin: auto;
    }
}


@media (min-width: 1000px) {
    .contact {
        flex-direction: row;
    }
}