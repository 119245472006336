.background {
    width: 100%;
    min-height: calc(100vh - 5rem);
    margin-top: 6rem;
    background: var(--backgroundCustom);
}


/* START section */

.start__background {
    background: rgb(var(--background));
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.start {
    height: 6rem;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start__img {
    width: 100%;
    height: auto;
    animation: resize 20s ease-out forwards;
}

.start__blackCover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(var(--background), 0.2);

}

@keyframes resize {
    100% {
        transform: scale(0.6);
    }
}



/* sections */

.section {
    max-width: 1200px;
    margin: 0 auto 0 auto;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.descSection {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.descContainer {
    width: calc(100% - 4rem);
    padding: 1rem;
    transform: translateY(4rem);
    background: white;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.imgContainer {
    width: 100%;
    height: 100%;
}

.descItemContainer {
    display: flex;
    padding: 0 0 0 0.5rem;
}

.descItemSvg {
    flex: 0 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    fill: rgb(var(--text));
    transform: rotate(180deg);
    opacity: 0.5;
}

.descItemText {
    padding: 0.3rem 0 0 0.2rem;
    font-size: 1rem;
    line-height: 2rem;
    color: var(--text);
}

.descText::first-letter {
    font-size: 200%;
    color: rgb(var(--accent));
    font-weight: bold;
}


/* btn */

.btnContainer {
    padding-top: 2rem;
    display: flex;
}

.btn {
    font-size: 1rem;
    letter-spacing: 0.2rem;
    background-color: transparent;
    color: white;
    font-weight: bold;
    opacity: 0.8;
    text-decoration: none;
}

.btn__accentText {
    color: rgb(var(--accent));
}

.btn:hover {
    opacity: 1;
}

.btn:active {
    color: rgb(var(--accent));
}

.rightArrow {
    border: solid rgb(var(--accent));
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    animation: movement 1s linear infinite;
}

@keyframes movement {
    50% {
        transform: translateX(50%) rotate(-45deg) scale(1.2);
    }
}

/* media */

@media (min-width: 500px) {
    .descItemContainer {
        padding: 0 0 0 1.5rem;
    }
}

@media (min-width: 800px) {

    .section {
        padding-bottom: 8rem;
    }

    .start {
        max-width: 1200px;
        margin: auto;
        height: 12rem;
    }

    .descContainer {
        width: calc(100% - 16rem);
        padding: 2rem;
        transform: translateY(8rem);
    }

    .descTitle {
        font-size: 2rem;
    }
}