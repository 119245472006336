.background {
    width: 100%;
    min-height: calc(100vh - 5rem);
    margin-top: 6rem;
    background: var(--backgroundCustom);
}


/* START section */

.start__background {
    background: rgb(var(--background));
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.start {
    height: 6rem;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.start__img {
    width: 100%;
    height: auto;
    animation: resize 20s ease-out forwards;
}

.start__blackCover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(var(--background), 0.2);
}

@keyframes resize {
    100% {
        transform: scale(0.6);
    }
}

/* sections */

.section {
    max-width: 1200px;
    margin: auto;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.head {
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem 0;
}

.descText {
    font-size: 1rem;
    padding: 0.5rem 0;
    color: var(--text);
    line-height: 1.5rem;
}

.slider {
    width: 100%;
    height: 100%;
}

@media (min-width: 800px) {

    .start {
        max-width: 1200px;
        margin: auto;
        height: 12rem;
    }
}