/* base in index.css */

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
}

html,
body {
  font-size: 100%;
  overflow-x: hidden;
  background: black;

  /* scrollbar ONLY for mozilla, scrollbar-width - "auto" or "thin"  */
  scrollbar-width: thin;
  scrollbar-color: var(--accent) gray;

}

:root {
  /* colors */

  --accent: 255, 120, 40;
  /* #ff7828 */

  --text: #5c6f87;

  --background: 40, 40, 40;

  --backgroundCustom: linear-gradient(90deg, rgb(240, 240, 240) 0%, rgb(221, 221, 221) 50%, rgb(240, 240, 240) 100%);
}

/* custom scroll bar in body (mobile not work) */
body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: black;
}

body::-webkit-scrollbar-thumb {
  background: rgba(var(--accent), 0.6);
  border-radius: 999px;
}


/* ALL */

.img {
  width: 100%;
  height: 100%;
  display: block;
}

.descHead {
  border-left: rgb(var(--accent)) 0.2rem solid;
  padding-left: 1rem;
}

.desc {
  letter-spacing: 0.3rem;
  font-size: 1rem;
  padding: 0.5rem 0;
  color: var(--text);
}

.desc__whiteText {
  color: white;
}

.descTitle {
  font-size: 1.2rem;
  color: rgb(var(--accent));
  padding: 0.5rem 0;
  font-weight: bold;
}


@media (min-width:500px) {

  .descHead {
    padding-left: 1.5rem;
  }

  .descTitle {
    font-size: 2rem;
  }
}