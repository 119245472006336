/* paginations (dot) color */
.swiper-pagination-bullet-active {
    background: rgb(var(--accent)) !important;
}

/* arrows color */
.swiper-button-prev,
.swiper-button-next {
    color: rgb(var(--accent)) !important;
}

/* arrows size */
.swiper-button-next::after,
.swiper-button-prev:after {
    font-size: 2rem !important;
}

/* image container */
.swiper-slider-ontainer-custom {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* padding: 0.5rem; */
}